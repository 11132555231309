@import "../../../colours";
@import "../../../mixins";

.main-header {

  .navbar-nav {

    .nav-item {
      .nav-link {
        &.clickable {
          cursor: pointer;
        }

        .image {
          width: 30px;
          text-align: center;
          border-radius: 2px;

          &:hover {
            background-color: $grey-xlight;
          }

          img {
            width: 25px;
            height: auto;
          }
        }
      }
    }

    .branding-logo {
      width: 180px;
      display: flex;
      align-items: center;

      img {
        width: 100%;
        height: auto;
      }
    }

  }

}