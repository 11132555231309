@import "../../../../colours";

.booking-replacement-confirmation-modal {
  display: block;
  background-color: $scrim-dark;
  animation-name: rep-modal-background-did-appear;
  animation-duration: 200ms;

  &.dismissing {
    animation-direction: reverse;

    .modal-dialog {
      animation-direction: reverse;
    }
  }

  .modal-dialog {
    animation-name: rep-modal-did-appear;
    animation-duration: 200ms;
  }
}

@keyframes rep-modal-background-did-appear {
  0% { opacity : 0 }
  100% { opacity : 1 }
}

@keyframes rep-modal-did-appear {
  0% { opacity : 0; transform: translateY(-20%) }
  30% { opacity : 0; transform: translateY(-20%) }
  90% { opacity : 1; transform: translateY(5%) }
  100% { opacity : 1; transform: translateY(0) }
}