@import "../../../colours";
@import "../../../mixins";

.user-account-modal {

  .user-account-backdrop {
    //background-color: $scrim-dark;
    position: absolute;
    inset: 0;
    z-index: 1039;
  }

  .user-account-modal-dialog {
    width: 280px;
    height: auto;
    background-color: $background-primary;
    color: $text-colour;
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 0 0 5px 5px;
    box-shadow: 0 5px 10px $shadow-heavy;
    z-index: 1040;
    overflow: hidden;
    animation-name: user-modal-did-appear;
    animation-duration: 200ms;
    animation-timing-function: ease-out;

    .user-account-modal-dialog-user-widget {
      display: flex;
      align-items: center;
      padding: 10px;

      .user-widget-icon {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        @include background-image-centre-crop;
        display: block;
        border: 2px solid $border-xlight;
      }

      .user-widget-name {
        flex: 1 1;
        font-size: large;
        font-weight: bold;
        display: block;
        overflow:hidden;
        text-overflow: ellipsis;
        padding: 0 0 0 0.5rem;
        white-space: nowrap;
      }
    }

    .user-account-modal-dialog-action {
      display: flex;
      align-items: center;
      padding: 5px;
      cursor: pointer;
      border-top: 1px solid $grey-light;

      &:hover {
        background-color: $grey-xlight;
      }

      .action-icon {
        width: 30px;
        height: 30px;
        @include background-image-centre;
      }

      .action-text {
        display: block;
        flex: 1 1;
        font-size: medium;
        margin-left: 0.5rem;
      }
    }
  }

  @keyframes user-modal-did-appear {
    0% { opacity : 0; transform: rotateY(-20deg) }
    100% { opacity : 1; transform: rotateY(0) }
  }

}