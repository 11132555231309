@import '../../../../mixins';

.worker-report-detail-screen {

  .image-thumbs {
    width: 100%;
    display: block;

    .image-thumb {
      width: 120px;
      height: 120px;
      display: inline-block;
      cursor: pointer;
      @include background-image-centre-crop;
    }
  }

}