@import "../../colours";
@import "../../mixins";

.site-map-component {

  .card {
    border: 1px solid $grey-light;
  }

  .header-action {
    padding: 2px 5px;
    line-height: 1.2rem;
  }

  .bedroom-bed {
    width: 100%;
    display: block;
    border: 1px solid $grey-light;
    border-radius: 5px;
    padding: 5px;
    cursor: pointer;
    transition: background-color 100ms;
    position: relative;

    &:hover {
      background-color: $grey-xlight;
    }

    .bed-slots {
      width: 100%;
      display: flex;
      align-items: center;

      .bed-slot {
        flex: 1 1;
        min-height: 40px;
        display: block;
        border-radius: 5px;

        text-align: center;

        .bed-slot-icon {
          max-width: 40px;
          display: inline-block;
          @include background-image-centre;
        }

        //&:hover {
        //  background-color: $grey-xlight;
        //}
      }
    }

    .bed-name {
      height: 1.2rem;
      font-size: small;
      font-weight: bold;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .info-popover {
      width: 120px;
      position: absolute;
      bottom: 100%;
      display: none;
      margin-left: -40px;

      .speech-body {
        border-radius: 5px;
        background-color: $background-primary;
        color: $text-colour;
        padding: 10px;
        border: 1px solid $grey-light;
      }

      .speech-bottom {
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 15px 10px 0 10px;
        border-color: $background-primary transparent transparent transparent;
        margin: auto;
      }
    }

    .bed-icon {
      width: 30px;
      height: 30px;
      display: none;
      margin: auto;
      @include background-image-centre
    }
  }

  &.map-small {
    .header-action {
      line-height: 0.8rem;

      .btn-icon {
        width: 0.8rem;
        height: 0.8rem;
      }
    }

    .bedroom-bed {
      height: 50px;
      min-width: 50px;
      position: relative;
      //overflow: hidden;

      .bed-slot {
        display: none;
      }

      .bed-name {
        display: none;
      }

      &:hover {
        .info-popover {
          display: block;
        }
      }

    }

    .bed-icon {
      display: block;
    }

  }

}