@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,700');

@import "./colours";
@import "./dimensions";
@import "./tokyo";
@import "./mixins";

body {
  font-family: 'Source Sans Pro', 'Open Sans', sans-serif !important;
  background-color: $background-page;
}

.content-wrapper {
  background-color: $background-page !important;
}

label {
  font-weight: bold;
  margin-bottom: 0;
  margin-top: 0.5rem;
}

.body-locked {
  overflow: hidden !important;
}

.app-screen {
  padding-bottom: 40px;
}

.header-wrapper {
  margin-bottom: 20px;
}

.badge.clickable, .form-control.clickable {
  cursor: pointer;
}

h1 {
  font-weight: bold;
}

h3 {
  font-size: 20pt !important;
  font-weight: 800 !important;
  margin-bottom: 5px !important;
}

h4 {
  font-size: 1.5rem !important;
  font-weight: 500 !important;
}

.container {
  width: 100% !important;
}

.card {
  box-shadow: none !important;

  .card-header {
    background-color: #EBEBEB;
    box-shadow: none !important;
  }
}

.btn {
  .btn-icon {
    width: 1.2rem;
    height: 1.2rem;
    display: inline-block;
    line-height: 1.2rem;
    @include background-image-centre;
  }

  &.btn-outline-dark {
    &:hover {
      .btn-icon {
        filter: invert(99%) sepia(2%) saturate(337%) hue-rotate(174deg) brightness(120%) contrast(100%);
      }
    }
  }

}

.modal {
  display: block !important;
  background-color: $scrim-dark;
  animation-name: generic-modal-background-did-appear;
  animation-duration: 200ms;
  overflow: auto !important;

  .close-button {
    width: 1.8rem;
    height: 1.8rem;
    @include background-image-centre;
    display: inline-block;
    cursor: pointer;
    border-radius: 5px;

    &:hover {
      background-color: $grey-xlight;
    }
  }

  .modal-dialog {
    animation-name: generic-modal-did-appear;
    animation-duration: 200ms;
  }

  &.dismissing {
    animation-direction: reverse;

    .modal-dialog {
      animation-direction: reverse;
    }
  }
}

@keyframes generic-modal-background-did-appear {
  0% { opacity : 0 }
  100% { opacity : 1 }
}

@keyframes generic-modal-did-appear {
  0% { opacity : 0; transform: translateY(-20%) }
  30% { opacity : 0; transform: translateY(-20%) }
  90% { opacity : 1; transform: translateY(5%) }
  100% { opacity : 1; transform: translateY(0) }
}

table {
  thead {
    border-radius: 5px;

    tr {
      border-bottom: none;
    }

    th {
      background-color: #eaeaea;
      border-bottom: none;

      &:first-child {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }

      &:last-child {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }
  }

  tbody {
    tr {
      &:last-child {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;

        td {
          &:first-child {
            border-bottom-left-radius: 5px;
          }
        }

        td {
          &:last-child {
            border-bottom-right-radius: 5px;
          }
        }
      }
    }
  }

  .sortable {
    cursor: pointer;
    background-position: right center;
    background-repeat: no-repeat;
    padding-right: 20px;
  }
}

.segment-tab-bar {
  .segment-container {
    background-color: $white;
  }
}

.image-thumbnail {
  border: 1px solid $grey-light;
  border-radius: 5px;
  background-color: $grey-xlight;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
}

.file-hide {
  width: 1px;
  height: 1px;
  overflow:hidden;
  position: absolute;
  left: -9999px;
  top: -9999px;
}

.empty-message {
  text-align: center !important;
  font-style: italic;
  font-size: large;
}

div.empty-message, span.empty-message {
  display: block;
}

.list-group-item {
  &.clickable {
    cursor: pointer;

    &:hover:not(.active) {
      background-color: $grey-xlight;
    }

    &.active {
      &:not(:first-child) {
        border-top-color: #FFF;
      }

      &:hover {
        background-color: darken(#007bff, 3%);
      }
    }
  }
}

.card {
  .card, &.outlined {
    border:1px solid $grey-light;
  }
}

.animate-screen-content {
  animation-name: app-screen-appear;
  animation-duration: 200ms;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;

  @keyframes app-screen-appear {
    0% { opacity : 0; transform: translateY(20%);}
    100% { opacity : 1; transform: translateY(0); }
  }
}

.progress {
  .progress-bar {
    height: 100%;
  }
}

/* HEADER NAV CONTENT */

.header-nav-content {
  //display: flex;
  //align-items: center;
  //padding-left: 40px;

  .header-project-selector {
    display: inline-block;
    border-radius: 5px;
    cursor: pointer;
    padding: 5px 10px;

    .label {
      font-size: small;
      font-weight: 800;
    }

    .project-name {
      font-size: medium;
    }
  }

}

/* CARD */

.card {
  &.clickable {
    cursor: pointer;
  }

  .card-header {
    h1, h2, h3, h4 {
      margin: 0;
    }
  }
}

/* OFFCANVAS **/

.offcanvas {
  visibility: visible !important;
  animation-duration: 120ms;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in;
  transform: translateX(0);
  z-index: 1061 !important;

  &.offcanvas-start {
    animation-name: offcanvas-appear-start !important;
  }

  &.offcanvas-end {
    animation-name: offcanvas-appear-end !important;
  }

  &.offcanvas-bottom {
    animation-name: offcanvas-appear-bottom !important;
  }

  &.offcanvas-top {
    animation-name: offcanvas-appear-top !important;
  }

  .offcanvas-footer {
    padding: 10px;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
  }
}

.offcanvas-scrim {
  background: rgba(0, 0, 0, 0.6);
  animation-name: offcanvas-scrim-appear;
  animation-duration: 120ms;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1060;
}

.dismissing {
  .offcanvas {
    animation-direction: reverse !important;
    animation-play-state: running !important;
  }

  .offcanvas-scrim {
    animation-direction: reverse !important;
    animation-play-state: running !important;
  }
}

@keyframes offcanvas-scrim-appear {
  0% {
    opacity: 0
  }
  100% {
    opacity: 1
  }
}

@keyframes offcanvas-appear-start {
  0% {
    transform: translateX(-100%)
  }
  100% {
    transform: translateX(0)
  }
}

@keyframes offcanvas-appear-end {
  0% {
    transform: translateX(100%)
  }
  100% {
    transform: translateX(0)
  }
}

@keyframes offcanvas-appear-bottom {
  0% {
    transform: translateY(100%)
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes offcanvas-appear-top {
  0% {
    transform: translateY(-100%)
  }
  100% {
    transform: translateY(0);
  }
}

/* SCREEN TITLE & ACTIONS */

.screen-actions {
  text-align: right;
  align-items: center;

  & > * {
    animation-name: screen-action-appear;
    animation-duration: 120ms;
    animation-timing-function: ease-in;
    animation-fill-mode: forwards;
  }

  @keyframes screen-action-appear {
    0% { opacity : 0; transform: translateX(40%) }
    100% { opacity : 1; transform: translateX(0) }
  }
}

.image-preview {
  background: $grey-xlight;
  border: 1px solid $grey-light;
  border-radius: 10px;
  @include background-image-centre;

  &.center-crop {
    background-size: cover;
  }

  &.clickable {
    cursor: pointer;
  }
}

.nav-item {
  padding: 4px 0;

  .nav-link {
    color: $text-colour;
  }

  .nav-treeview {
    display: flex !important;

    .nav-item {
      width: 100%;
      padding: 2px 0;
      font-size: small;
      animation-name: nav-item-did-appear;
      animation-duration: 100ms;

      .nav-link {
        width: 94%;
        padding: 5px;
        margin-left: 5%;
        color: $text-colour;
      }
    }
  }
}

.sidebar-collapse {
  .main-sidebar {
    .nav-treeview {
      .nav-item {
        .nav-link {
          width: 2.4rem;
          margin-left: 0.6rem;
        }
      }
    }

    &:hover {
      .nav-treeview {
        .nav-item {
          .nav-link {
            width: 94%;
            padding: 5px;
            margin-left: 5%;
          }
        }
      }
    }
  }

}

@keyframes nav-item-did-appear {
  0% { opacity : 0; scale: 0 }
  100% { opacity : 1; scale : 1 }
}

.main-sidebar {
  .nav-link {
    border-radius: 999px !important;
    background-color: $background-primary !important;

    &:hover, &.active {
      color: $text-colour-inverse !important;
      background-color: $navigation-active !important;
      box-shadow: 0 2px 8px 2px rgba(0, 0, 0, .08) !important;
    }
  }
}

.main-container {
  display: flex;

  .side-navigation {
    width: 25%;
    max-width: 300px;
    box-sizing: border-box;
    padding: 0 10px;
  }

  .primary-content {
    flex: 1 1;

  }
}

/** Make sidebar sit below header **/
.main-sidebar {
  height: 100%;
  top: auto !important;
  background: none !important;
}

/** Prevent Header from moving when sidebar expanded or collapsed **/
.main-header {
  margin-left: 0 !important;
}

.sidebar-mini.sidebar-collapse {
  .main-header {
    margin-left: 0 !important;
  }

  .main-sidebar {
    &:hover {
      background: rgb(0,0,0) !important;
      background: linear-gradient(90deg, rgba(0,0,0,0.19931722689075626) 0%, rgba(0,0,0,0) 100%) !important;
    }
  }
}

.hops-segment-tab-bar {
  display: block !important;

  .segment-container {
    border-radius: 0 !important;
    border: none !important;
    border-bottom: 2px solid $background-primary !important;
    background-color: unset !important;
    padding: 0 !important;

    .segment-tab {
      border-radius: 30px !important;
      background-color: $background-primary !important;
      border: none !important;
      margin-bottom: 5px !important;
      box-shadow: 0px -4px 6px 0px rgba(0,0,0,.08) !important;
      font-weight: normal !important;

      &.active {
        border-radius: 12px 12px 0 0 !important;
        transform: translateY(0) !important;
        position: relative !important;
        background-color: $background-primary !important;
        color: $text-colour !important;

        /** Draw a box to join the bottom of the tab to the container border **/
        &::after {
          content: '';
          height: 5px;
          display: block;
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          background-color: $background-primary;
          margin-bottom: -5px;
        }
      }
    }
  }

}