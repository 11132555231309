@import "../../../../mixins";

$animation-length : 400ms;

.worker-report-entry-success-screen {

  @mixin image-filter {
    filter: invert(54%) sepia(34%) saturate(6435%) hue-rotate(88deg) brightness(106%) contrast(115%);
  }

  .tick-icon {
    width: 100%;
    height: 300px;
    @include background-image-centre;
    @include image-filter;
    position: relative;
    animation-name: circle-did-appear;
    animation-duration: $animation-length;
    animation-timing-function: ease-in-out;
  }

  .tick-icon-content {
    width: 50%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin-left: 25%;
    @include background-image-centre;
    animation-name: tick-did-appear;
    animation-duration: $animation-length;
    animation-timing-function: ease-in-out;
  }

  .button-text-animate {
    animation-name: button-text-did-appear;
    animation-duration: $animation-length;
    animation-timing-function: ease-in;

    &.delay {
      animation-delay: 100ms;
    }
  }

  @keyframes circle-did-appear {
    0% { opacity: 0; transform: scale(2);}
    10% { opacity: 0; transform: scale(2);}
    80% { opacity: 1; transform: scale(0.8);}
    100% { opacity: 1; transform: scale(1);}
  }

  @keyframes tick-did-appear {
    0% { opacity: 0; transform: rotate(20deg) }
    70% { opacity: 0; transform: rotate(20deg) }
    100% { opacity: 1; transform: rotate(0deg) }
  }

  @keyframes button-text-did-appear {
    0% { opacity: 0; transform: translateY(10%) }
    70% { opacity: 0; transform: translateY(10%) }
    100% { opacity: 1; transform: translateY(0) }
  }

}