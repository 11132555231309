@import "../../mixins";

$icon-size : 2.4rem;

.hops-header-bar {
  width: 100%;
  padding: 10px;

  .header-content-container {
    display: flex;
    align-items: center;

    .spacer {
      flex: 1 1;
    }

    .menu-action {
      width: $icon-size;
      height: $icon-size;
      border-radius: 5px;
      display: block;
      @include background-image-centre;
      position: relative;

      .badge {
        position: absolute;
        right: 0;
        top: 0;
      }

      &.clickable {
        cursor: pointer;
      }
    }

    .header-title {
      font-size: large;
      font-weight: bold;
    }

  }

}