@import "../../colours";

.collapsable-filter-component {
  width: 100%;
  display: block;

  .collapsable-area {
    animation-name: collapse-area-did-appear;
    animation-duration: 200ms;
  }
  
  @keyframes collapse-area-did-appear {
    0% { opacity: 0 }
    100% { opacity: 1 }
  }

}