.message-detail-screen {

  .message-screen-did-appear {
    animation-name: message-screen-appears;
    animation-duration: 200ms;
  }

  .message-date {
    font-size: medium;
    font-weight: bold;
    display: block;
  }

  .message-title {
    font-size: x-large;
    font-weight: 800;
  }

  .message-body {
    font-size: medium;
    padding-bottom: 40px;
  }

  @keyframes message-screen-appears {
    0% { opacity : 0; transform: translateX(15%) }
    20% { opacity : 0; transform: translateX(15%) }
    100% { opacity : 1; transform: translateX(0) }
  }

}