@import "../../colours";
@import "../../mixins";

.user-info-widget {

  .info-area {
    display: flex;
    align-items: center;

    .info-avatar {
      width: 60px;
      height: 60px;
      border: 2px solid $grey-dark;
      border-radius: 50%;
      @include background-image-centre;
      display: block;
    }

    .info-content {
      display: block;
      flex: 1 1;
      margin-left: 10px;

      .info-content-name {
        font-size: large;
        font-weight: bold;
        display: block;
      }

      .info-content-role {
        font-size: medium;
        display: block;
      }

      .info-content-action {
        display: block;
      }
    }
  }

}