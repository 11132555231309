@mixin background-image-centre {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

@mixin background-image-centre-crop {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}