.booking-detail-screen {

  .placement-occupancy {
    position: relative;
    padding-left: 30px;
    padding-bottom: 10px;

    &:first-child {
      .timeline-line {
        margin-top: 10px;
      }
    }

    &:last-child {
      .timeline-line {
        height: 25%;
      }
    }

    .timeline-indicator {
      width: 20px;
      height: 20px;
      background-color: black;
      border-radius: 50%;
      position: absolute;
      left: 0;
      top: 0;
      margin-left: 3px;
      margin-top: 5px;
      border: 2px solid black;

      &.unfilled {
        background-color: white;
      }
    }

    .timeline-line {
      width: 1px;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      border: 3px solid black;
      margin-left: 10px;

      &.unfilled {
        border-style: dashed;
      }
    }

    .occupancy-date {
      display: block;
      font-weight: bold;
    }

    .occupancy-container {
      background-color: white;
      padding: 10px 15px;
      border-radius: 10px;

      .booking-heading {
        font-size: small;
        font-weight: bold;
        font-style: italic;
      }
    }

    .placement-occupancy-message {
      font-size: small;
      font-style: italic;
      font-weight: bold;
      text-align: center;

      &.success {
        background-color: #CEFFC8;
      }

      &.warning {
        background-color: #FCFFC9;
      }
    }
  }

}