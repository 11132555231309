@import "../../../colours";
@import "../../../mixins";

.login-screen {
  min-height: 100vh;
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  background-attachment: fixed;
  @include background-image-centre-crop;

  .login-container {
    width: 100%;
    background: rgba(255, 255, 255, 0.15);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5.4px);
    -webkit-backdrop-filter: blur(5.4px);
    padding: 20px;
    color: $text-colour-inverse;

    .login-title {
      font-size: medium;
      font-weight: bold;
      display: block;
      text-align: center;
    }

    .login-button {
      width: 100%;
    }

    .forgotten-link {
      width: 100%;
      text-decoration: underline;
      cursor: pointer;
      display: block;
      padding: 4px 10px;
      border-radius: 5px;
    }
  }

  .system-logo {
    width: 180px;
    height: 100px;
    position: fixed;
    bottom: 0;
    right: 0;
    @include background-image-centre;
    display: block;
    margin: 20px;
  }

}