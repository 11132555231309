@import "../../../../colours";
@import "../../../../mixins";

$read-indicator-size : 0.8rem;
$click-indicator-size : 2.4rem;

.message-list-screen {

  .message-item {
    border-bottom: 1px solid $grey-light;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: background-color 100ms;

    &:hover {
      background-color: $grey-xlight;
    }

    .read-indicator {
      width: $read-indicator-size;
      margin: 0 0 0 10px;

      .circle {
        width: $read-indicator-size;
        height: $read-indicator-size;
        background: $red-primary;
        border-radius: 50%;
        display: block;
      }
    }

    .main-content {
      flex: 1 1;
      margin: 5px 10px;

      .message-date {
        font-size: small;
        font-weight: 800;
        text-transform: uppercase;
      }

      .message-title {
        font-size: large;
        font-weight: bold;
        display: block;
      }

      .message-body {
        display: block;
      }
    }

    .action-indicator {
      width: $click-indicator-size;
      height: $click-indicator-size;
      @include background-image-centre;
    }

  }

  .message-end {
    display: block;
    padding: 10px;
    margin-top: 10px;
    text-align: center;
    font-weight: bold;
    color: $grey-primary;
    cursor: default;
    user-select: none;
  }

  .message-is-dismissing {
    animation-name: message-is-dismissing;
    animation-duration: 200ms;
    animation-fill-mode: forwards;
  }

  @keyframes message-is-dismissing {
    0% { transform: translateX(0); opacity : 1 }
    80% { transform: translateX(-15%); opacity: 0}
    100% { transform: translateX(-15%); opacity: 0}
  }

}