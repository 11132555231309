@import "../../colours";

$animation-duration: 400ms;

.tokyo-lightbox {
  position: fixed;
  inset: 0;
  background: $scrim-light;
  z-index: 1108;
  display: flex;
  flex-direction: column;
  animation-name: lightbox-did-appear;
  animation-duration: $animation-duration;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in;

  &.dismissing {
    animation-direction: reverse;

    .image-display {
      animation-direction: reverse;
    }

    .image-thumbnails {
      animation-direction: reverse;
    }
  }

  .close {
    width: 50px;
    height: 50px;
    position: absolute;
    right: 0;
    top: 0;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-color: $scrim-light;
    cursor: pointer;
    border-radius: 5px;
  }

  .image-display {
    flex: 1 1;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    margin: 10px;
    animation-name: lightbox-image-did-appear;
    animation-duration: $animation-duration;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in;
  }

  .image-thumbnails {
    display: block;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    text-align: center;
    animation-name: lightbox-thumbnails-did-appear;
    animation-duration: $animation-duration;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in;

    .thumbnail {
      width: 80px;
      height: 80px;
      display: inline-block;
      opacity: 0.8;
      transition: opacity 200ms, border 200ms;
      margin-right: 10px;

      &:hover {
        opacity: 1;
      }

      &:first-child {
        margin-left: 10px;
      }

      &:last-child {
        margin-right: 0;
      }

      &.active {
        opacity: 1;
        border-color: $brand-primary !important;
      }

    }
  }

  @keyframes lightbox-did-appear {
    0% { opacity : 0 }
    100% { opacity : 1 }
  };

  @keyframes lightbox-image-did-appear {
    0% { opacity : 0; transform: translateY(-20%) }
    50% { opacity : 0; transform: translateY(-20%) }
    100% { opacity : 1; transform: translateY(0) }
  }

  @keyframes lightbox-thumbnails-did-appear {
    0% { opacity : 0; transform: translateY(20%) }
    50% { opacity : 0; transform: translateY(20%) }
    100% { opacity : 1; transform: translateY(0) }
  }

}