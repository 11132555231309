@import "../../../colours";

$animation-length : 200ms;

.video-carousel-modal {
  display: block !important;
  background-color: $scrim-dark !important;
  animation-name: modal-background-did-appear;
  animation-duration: $animation-length;

  &.dismissing {
    animation-direction: reverse !important;

    .modal-dialog {
      animation-direction: reverse !important;
    }
  }

  .modal-dialog {
    animation-name: video-carousel-modal-did-appear;
    animation-duration: $animation-length;
    animation-timing-function: ease-out;
  }

  .video-item {
    width: 200px;
    display: inline-block;
    margin-top: 10px;
    margin-right: 10px;
    padding: 5px;
    border: 1px solid $grey-light;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 200ms;

    &:hover {
      background-color: $grey-xlight;
    }

    &.active {
      background-color: $blue-primary;
      color: $text-colour-inverse;
    }

    .video-thumb {
      width: 100%;
      background-color: #000;
      border-radius: 5px;
    }

    .video-title {
      font-weight: 800;
      text-align: center;
      margin-top: 5px;
      font-size: small;
    }
  }

  @keyframes modal-background-did-appear {
    0% { opacity : 0 }
    100% { opacity : 1 }
  }

  @keyframes video-carousel-modal-did-appear {
    0% { opacity : 0; transform: translateY(-20%) }
    30% { opacity : 0; transform: translateY(-20%) }
    90% { opacity : 1; transform: translateY(5%) }
    100% { opacity : 1; transform: translateY(0) }
  }

}