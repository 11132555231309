@import "../../../../mixins";

.admin-dashboard-screen {

  .info-box {

    .site-summary-sub-summary {
      font-size: medium;
      margin-bottom: 5px;

      .site-summary-sub-sub-summary {
        font-size: small;
      }
    }

    .info-box-icon {
      .ratio {
        @include background-image-centre;
      }
    }

  }

  .progress-description.contain-height {
    max-height: 16rem;
    overflow: auto;
  }

}