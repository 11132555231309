.former-form {

  .invalid-feedback {
    display: block;
  }

  .former-image-preview-container {
    .former-image-preview-row {
      display: flex;

      .former-image-preview {
        flex: 1 1;
      }
    }
  }

}