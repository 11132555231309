@import "../../colours";
@import "../../dimensions";

.tokyo-navigation-list {
  width: 100%;
  display: block;

  .navigation-heading {
    display: block;
    font-weight: bold;
    padding: $pill-padding-vertical $pill-padding-horizontal;
    cursor: default;
    color: $blue-primary;
    font-size: large;
  }

  .navigation-contain {
    display: block;

    .navigation-child {
      display: block;
      margin-left: 15px;
    }
  }

  .navigation-item {
    display: block;
    font-weight: bold;
    padding: $pill-padding-vertical $pill-padding-horizontal;
    border: 1px solid rgba(0, 0, 0, 0);
    border-radius: $pill-border-radius;
    text-align: left;
    cursor: default;

    &:hover {
      border-color: rgba(0,0,0,0);
    }

    &.clickable {
      cursor: pointer;

      &:hover {
        border-color: $grey-primary;
      }

      &.active {
        background: $blue-primary;
        color: $text-colour-inverse;
      }
    }
  }

  .navigation-heading, .navigation-item {
    margin-top: 5px;
    margin-bottom: 5px;

    &:nth-child(1) {
      margin-top: 0;
    }

    &:last-child {
      margin: 5px !important;
    }
  }

}