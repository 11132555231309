@import "../../../../colours";
@import "../../../../mixins";

.inspection-template-editor-screen {

  .question-title-actions {
    flex: 1 1;
    text-align: right;
    margin-right: 10px;

    .question-title-action {
      width: 1.8rem;
      height: 1.8rem;
      @include background-image-centre;
      border:1px solid $grey-light;
      border-radius: 5px;
      cursor: pointer;
      display: inline-block;
      margin-left: 5px;

      &:hover {
        background-color: $grey-xlight;
      }
    }
  }

}