.user-messages-modal {

  .message-group {

    .list-group-item-action {
      cursor: pointer;
    }

    .message-date {
      font-weight: 800;
      font-size: small;
    }

    .message-subject {
      font-weight: bold;
    }

  }

}