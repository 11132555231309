@import "../../../../mixins";
@import "../../../../colours";

.organisation-select-screen {

  .organisation-item {
    display: flex;
    align-items: center;

    .organisation-image {
      width: 60px;
      height: 60px;
      border: 1px solid $grey-xlight;
      border-radius: 5px;
      background-color: $white;
      @include background-image-centre;
    }

    .content-area {
      flex: 1 1;
      margin-left: 10px;

      .title {
        font-size: large;
        font-weight: bold;
      }

      .message {
        font-size: medium;
      }
    }
  }

}